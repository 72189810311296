import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { resolve } from 'q';
import { Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators'; 
import { environment } from 'src/environments/environment';
import {
  ToastController,
  LoadingController,
  AlertController,
  NavController
} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ChaptersService {

  constructor(private router: Router,
    private toastController: ToastController,
  	public http: HttpClient, 
    private loadingCtrl: LoadingController,
    private nav: NavController, public alertController: AlertController) { }

  	getApiUrl()
	  {
	    return environment.lmsurl; 
	  }

	async presentToast(message, show_button, position, duration) {
	    const toast = await this.toastController.create({
	      message: message, 
	      position: position,
	      duration: duration
	    });
	    toast.present();
	  }

	chapters_api(body){
	    let authapiurl = this.getApiUrl();
	    let headers = new HttpHeaders();
	    let options = {
	      headers: headers
	    }
	      return this.http.post(authapiurl, body, options).pipe(map(res => res));
    }
}
