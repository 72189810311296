import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment'
import { StorageKeys } from '../models/storage.enums';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})

export class StaffingService {
  public accessToken;
  public userDetail;
  constructor(public http: HttpClient, public authService: AuthService, public storage: StorageService) { }

  getStaffing(param){
    return this.http.post<any>(environment.apiUrl + 'Staffing', param)
  }

  getEmpAssHistory(param){
    return this.http.post<any>(environment.apiUrl + 'Staffing/EmployeeAssignedHistory', param)
  }

  getProjectedData(param){
    return this.http.post<any>(environment.apiUrl + 'Staffing/Load_ProjectedData', param)
  }

}
