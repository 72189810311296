<app-header
  showBackButton
  [backButtonPath]="'/main/home'"
  showMenuButton
  showLogo
  showSettingIcons
></app-header>
<ion-content>
  <app-user-details title="Notifications"></app-user-details>
 
  <ion-card-content>
    <div *ngFor="let item of notificationlist">
      <ion-row>
        <ion-col size="1">
          <img
            class="title-icon"
            [src]="item.MessageIconID ?'assets/images/'+item.MessageIconID :''"
          />
        </ion-col>
        <ion-col size="10">
          <ion-label>{{item.MessageDesc}}</ion-label><br />
          <ion-label style="font-size: small"
            >{{item.MessageSentOn | notificatoinDaysAgo}}</ion-label
          >
        </ion-col>
      </ion-row>
      <hr class="rowLine" />
    </div>
  </ion-card-content>
</ion-content>
