import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from "src/environments/environment";
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/storage.enums';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  public library$ = new BehaviorSubject([]);
  public schoolCommittee$ = new BehaviorSubject([]);
  public schoolinfoLoading: boolean = false;
  public infoLoading: boolean = false;
  public accessToken: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.init();
  }

  async init() {
    this.infoLoading = true;
    this.accessToken = localStorage.getItem(StorageKeys.AccessToken)
  }

  async getLibraryInfo(msgType) {
    let controlName="Portal";
    this.infoLoading = true;
    let userDetail = await this.authService.getUserDetail();
    if (userDetail) {
      let param = {
        SchoolID: localStorage.getItem(StorageKeys.selectedCompany).toString(),
        FileType: msgType,
        ActionType: "GetLibrary",
        EmployeeID:userDetail.EmployeeID,
        UserID: userDetail.UserID,
        AccessToken: this.accessToken
      };

      if(userDetail.EmployeeID!=0){
        controlName="Employee"
      }

      this.http
        .post<any>(environment.apiUrl + controlName, param)
        .subscribe(
          (resp) => {
            this.library$.next(resp && resp.length > 0 ? resp : []);
            this.infoLoading = false;
          },
          (err) => {
            this.infoLoading = false;
            console.error(err);
          }
        );
    }
  }

  async getSchoolCommittee() {
    this.schoolinfoLoading = true;
    let userDetail = await this.authService.getUserDetail();

    if (userDetail) {
      let param = {
        SchoolID: localStorage.getItem(StorageKeys.selectedCompany).toString(),
        ActionType: "GetParentAssociation",
        UserID: userDetail.UserID,
        AccessToken: this.accessToken
      };

      this.http
        .post<any>(environment.apiUrl + "Portal", param)
        .subscribe(
          (resp) => {
            this.schoolCommittee$.next(resp && resp.length > 0 ? resp : []);
            this.schoolinfoLoading = false;
          },
          (err) => {
            this.schoolinfoLoading = false;
            console.error(err);
          }
        );
    }
  }
}
