import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-show-notifications',
  templateUrl: './show-notifications.page.html',
  styleUrls: ['./show-notifications.page.scss'],
})
export class ShowNotificationsPage implements OnInit {
  notificationlist: any;
  constructor(private notify: NotificationService) { }

  ngOnInit() {
    this.FetchAllNotifications();
  }

  FetchAllNotifications() {
    this.notify.FetchAllNotification().then(() => {
      this.notify.notificationAll$.subscribe((data) => {
        this.notificationlist = data;
      })
    })
  }
}
